import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Page404Component } from './error/page-404/page-404.component';


import { RouterModule } from '@angular/router';
// import { PipesModule } from '../pipes/pipes.module';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
// import { HeaderComponent } from './header/header.component';
// import { SidebarComponent } from './sidebar/sidebar.component';
// import { UploadComponent } from './upload/upload.component';
// import { MapComponent } from './map/map.component';
// import { AgmCoreModule } from '@agm/core';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatSidenavModule} from '@angular/material';
import {MatToolbarModule} from '@angular/material';
import {MatIconModule} from '@angular/material';
import {MatListModule} from '@angular/material';
import {MatExpansionModule, MatInputModule} from '@angular/material';
import {MatButtonModule} from '@angular/material';
import {MatMenuModule} from '@angular/material';
import {MatCardModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material';
import {MatRadioModule} from '@angular/material';
import {MatStepperModule } from '@angular/material';
import {MatTooltipModule} from '@angular/material';
import {MatOptionModule, MatSelectModule} from '@angular/material';

import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { AlertComponent } from './alert/alert.component';
import { DialogComponent } from './dialog/dialog.component';

/*

db.c_concept_investments.insert({
    "idConcept" : "3270",
    "description" : "Sistemas agrosilvopastoriles",
    "type" : "Forestal|Agricultura|Ganadería",
    "active" : true,
    "removed" : false
})

*/

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        // PipesModule,
        // AgmCoreModule.forRoot({
        //   apiKey: 'AIzaSyC6zl6sUq_QaRI58dMLYyBq1FSydpn_a8k'
        // })



    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule, MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatOptionModule, MatSelectModule, MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,

    MatDialogModule,
    MatSnackBarModule

    ],
    declarations: [
      Page404Component,
  //      HeaderComponent
        // SidebarComponent,
         BreadcrumbsComponent,
     //   UploadComponent,
        // MapComponent

        DialogComponent,
        AlertComponent,
    ],
    exports: [
        Page404Component,
    //    HeaderComponent
        // SidebarComponent,
         BreadcrumbsComponent,


     //    UploadComponent,
        // MapComponent,
        // AgmCoreModule
    ],

    entryComponents: [DialogComponent, AlertComponent],

})

export class SharedModule { }

