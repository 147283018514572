import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '../services/guards/login.guard';
import { PublicComponent } from './public.component';
import { Page404Component } from '../shared/error/page-404/page-404.component';
import { CreditRequestComponent } from './credit-request/credit-request.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { ReportsComponent } from './reports/reports.component';
import { FiraComponent } from './fira/fira.component';

const publicRoutes:
Routes = [
    {
        path: '', component: PublicComponent,
        children: [
            {
                path: '',
                component: CreditRequestComponent,
                canActivate: [ LoginGuard ],
                loadChildren: './credit-request/credit-request.module#CreditRequestModule'
            },
            {
                path: '',
                component: CatalogsComponent,
                canActivate: [ LoginGuard ],
                loadChildren: './catalogs/catalogs.module#CatalogsModule'
            },
            {
                path: 'reports',
                component: ReportsComponent,
                canActivate: [ LoginGuard ],
                data: { title: 'Reportes',
                route: [
                ]}
            },
            {
                path: 'fira',
                component: FiraComponent,
                canActivate: [ LoginGuard ],
                data: { title: 'Fira',
                route: [
                ]}
            },
            {
                path: '**',
                component: Page404Component,
                canActivate: [ LoginGuard ],
                data: {
                    title: 'Pagina no encontrada',
                    route: []
                }
            }
        ]
    }
];

export const PUBLIC_ROUTES = RouterModule.forChild( publicRoutes );

