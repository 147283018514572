import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.js';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  urlReport = `${ environment.endpoint.report }/requests`;
  constructor( private http: HttpClient ) { }

  ngOnInit() {
  }
}
