import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment.js';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
import { User } from '../../models/user.model';
@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  authenticated = false;
  public dataSession: any = {
    authorization: '',
    user: User,
    policies: [],
    menu: []
  };

  authorization = '';
  menu: any;
  policies: any[] = [];
  public user: User;
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient, private router: Router ) {
    console.log('Security service loaded');
    this.initSession();
  }
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  initSession() {
    const dataSessionString = localStorage.getItem('dataSession');
    if ( dataSessionString !== null ) {

      this.authenticated = true;
      const dataSessionParse = JSON.parse( dataSessionString );
      this.dataSession.authorization = dataSessionParse.authorization;
      this.dataSession.user = new User(
        dataSessionParse.user.name,
        dataSessionParse.user.firstLastName,
        dataSessionParse.user.secondLastName,
        dataSessionParse.user.user,
        dataSessionParse.user.role,
        dataSessionParse.user.brithday,
        dataSessionParse.user.gene,
        dataSessionParse.user.image,
        dataSessionParse.user.id,
        dataSessionParse.user.organization,
        dataSessionParse.user.creationDate,
        dataSessionParse.user.updateDate
      );
      this.dataSession.policies = dataSessionParse.policies;
      this.dataSession.menu = this.createMenu();

    } else {
    //  this.singOut();
    }
  }
// -----------------------------------------------------------------------------
//  Start session
// -----------------------------------------------------------------------------
  signIn( user: string, password: string, rememberme: boolean ) {
    if (rememberme) {
      localStorage.setItem('email', user);
    } else {
      localStorage.removeItem('email');
    }
    
    return this.http.post( `${ environment.endpoint.security }/login`, { user, password } ).pipe( map( (response: any) => {

      if (response.businessResponse.user.role === 'ROOT' ||
          response.businessResponse.user.role === 'ADMINISTRATOR' ||
          response.businessResponse.user.role === 'COMMON' ||
          response.businessResponse.user.role === 'FIRA' ) {

            this.authenticated = true;
            this.dataSession.authorization = response.businessResponse.authorization;
            this.dataSession.user = new User(
              response.businessResponse.user.name,
              response.businessResponse.user.firstLastName,
              response.businessResponse.user.secondLastName,
              response.businessResponse.user.email,
              response.businessResponse.user.role,
              response.businessResponse.user.brithday,
              response.businessResponse.user.gene,
              response.businessResponse.user.image,
              response.businessResponse.user._id,
              response.businessResponse.user.organization,
              response.businessResponse.user.creationDate,
              response.businessResponse.user.updateDate
            );
            this.dataSession.policies = response.businessResponse.policies;
            this.dataSession.menu = this.createMenu();
            localStorage.setItem('dataSession', JSON.stringify(this.dataSession));
            return response.businessResponse;

      } else {
        this.singOut();
        return null;
      }
    }));
  }
// -----------------------------------------------------------------------------
//  Close session
// -----------------------------------------------------------------------------
  singOut() {
    this.dataSession.authorization = '';
    this.dataSession.user = null;
    this.dataSession.policies = [];
    this.dataSession.menu = [];
    this.authenticated = false;
    localStorage.removeItem('dataSession');
    this.router.navigate(['/signin']);
  }
// -----------------------------------------------------------------------------
//  Return status of session
// -----------------------------------------------------------------------------
  isAuthenticated() {
    return this.authenticated;
  }
// -----------------------------------------------------------------------------
//  Return role
// -----------------------------------------------------------------------------
  getRole() {
    return this.dataSession.user.role;
  }
// -----------------------------------------------------------------------------
//  Return data user
// -----------------------------------------------------------------------------
  getUserAuthenticated() {
    return this.dataSession.user;
  }

  setUserAuthenticated( user: User) {
    this.dataSession.user = user;
    localStorage.setItem('dataSession', JSON.stringify(this.dataSession));
  }

createMenu( ) {
  const menu = {
    platform: [],
    administration: [],
    modules: []
  };

  if (this.dataSession.user.role === 'ROOT') {
      menu.platform = [];
  }

  if (this.dataSession.user.role === 'ROOT' || this.dataSession.user.role === 'ADMINISTRATOR') {
    menu.administration = [
    ];
  }

  if (this.dataSession.user.role === 'ROOT' || this.dataSession.user.role === 'ADMINISTRATOR' || this.dataSession.user.role === 'COMMON') {
      menu.modules = [
          {
            title: 'Apoyos',
            icon: 'how_to_vote',
            url: '/module/dashboard',
            submenu: [
                {
                  title: 'Solicitudes',
                  icon: 'event_available',
                  url: '/requests' }

            ]
        },
        {
          title: 'Reportes',
          icon: 'description',
          url: '/module/report',
          submenu: [
              {
                title: 'Exportar solicitudes',
                icon: 'event_available',
                url: '/reports'
              }

          ]
      }
      ];

  }


  if (this.dataSession.user.role === 'FIRA') {
    menu.modules = [
    {
      title: 'Solicitudes',
      icon: 'how_to_vote',
      url: '/module/fira',
      submenu: [
          {
            title: 'Aprobadas',
            icon: 'event_available',
            url: '/fira'
          }
      ]
  }];

}
  return menu;
}
}