import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( ) {
  }
// -----------------------------------------------------------------------------
//  Save data to local storage
// -----------------------------------------------------------------------------
  save( idData: string, data: any ): void {
    localStorage.setItem( idData, JSON.stringify( data ));
  }
// -----------------------------------------------------------------------------
//  get data from local storage
// -----------------------------------------------------------------------------
  get( idData: string ): any {
    return JSON.parse( localStorage.getItem( idData ) );
  }
// -----------------------------------------------------------------------------
//  Remove data from local storage
// -----------------------------------------------------------------------------
  remove( idData: string ): void {
    localStorage.removeItem( idData);
  }
}
