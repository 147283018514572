export const environment = {
  production      : false,
  name            : 'QA',
  endpoint: {
    publicWeb     : 'https://test.prosostenible.org',
    adminWeb      : 'https://admin.test.prosostenible.org',
    creditRequest : 'https://credit.api.test.prosostenible.org',
    resource      : 'https://resource.api.test.prosostenible.org',
    security      : 'https://security.api.test.prosostenible.org',
    notification  : 'https://notification.api.test.prosostenible.org',
    report        : 'https://report.api.test.prosostenible.org'
  }
};