import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-request',
  templateUrl: './credit-request.component.html',
  styleUrls: ['./credit-request.component.scss']
})
export class CreditRequestComponent implements OnInit {
  fixMenu = false;
  statusSocketService = false;
  businessName: string;
  businessList: any[] = [];
  menuAdministration: any[] = [];
  menuModules: any[] = [];
  menuPlatform: any[] = [];

  constructor() { }

  ngOnInit() {

  }

}
