import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment.js';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService } from '../platform/security.service';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class CreditService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient, private securityService: SecurityService ) {
    console.log('Credit service loaded');
  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
  save( data: any ) {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );

    if ( data._id === '' ) {

      return this.http.post( `${ environment.endpoint.creditRequest }/request`,  data , { headers } )
      .pipe( retry(5), map( (response: any) => {
            return response.businessResponse;

          }), catchError((err) => {
            switch ( err.status ) {
              case 401:
                this.securityService.singOut();
                break;
              default:
              break;
            }
            return throwError(err.status);
      }));

    } else {

      return this.http.patch( `${ environment.endpoint.creditRequest }/request/${ data._id }`,  data , { headers } )
      .pipe( retry(5), map( (response: any) => {
            return response.businessResponse;

          }), catchError((err) => {
            switch ( err.status ) {
              case 401:
                this.securityService.singOut();
                break;
              default:
              break;
            }
            return throwError(err.status);
      }));

    }

  }
// -----------------------------------------------------------------------------
//  evaluate Document
// -----------------------------------------------------------------------------
evaluateDocument( id: string, numQuestion: string, numDoc: string, evaluator: string, value: string, comment: string ) {

  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );

  return this.http.patch(
    `${ environment.endpoint.creditRequest }/request/${ id }/question/${ numQuestion }/document/${ numDoc }/${ value }`,
     { comment, evaluator }, { headers } ).pipe( retry(5), map( (response: any) => {

      console.log(response);

        return response.businessResponse;

      }), catchError((err) => {
        switch ( err.status ) {
          case 401:
            this.securityService.singOut();
            break;
          default:
          break;
        }
        return throwError(err.status);
  }));

}
// -----------------------------------------------------------------------------
//  evaluation
// -----------------------------------------------------------------------------
evaluation( id: any ) {

  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );

  return this.http.get( `${ environment.endpoint.creditRequest }/request/${ id }/evaluation`, { headers } )
  .pipe( retry(5), map( (response: any) => {
        return response.businessResponse;

      }), catchError((err) => {
        switch ( err.status ) {
          case 401:
            this.securityService.singOut();
            break;
          default:
          break;
        }
        return throwError(err.status);
  }));
}


approval( id: any,  basePoints: string, commentEvaluation: string  ) {
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );

  return this.http.patch( `${ environment.endpoint.creditRequest }/request/${ id }/approval`, { basePoints, commentEvaluation }, { headers } )
  .pipe( retry(5), map( (response: any) => {
        return response.businessResponse;

      }), catchError((err) => {
        switch ( err.status ) {
          case 401:
            this.securityService.singOut();
            break;
          default:
          break;
        }
        return throwError(err.status);
  }));
}

rejected( id: any ) {
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );

  return this.http.patch( `${ environment.endpoint.creditRequest }/request/${ id }/rejected`, { }, { headers } )
  .pipe( retry(5), map( (response: any) => {
        return response.businessResponse;

      }), catchError((err) => {
        switch ( err.status ) {
          case 401:
            this.securityService.singOut();
            break;
          default:
          break;
        }
        return throwError(err.status);
  }));
}

cancel( id: any ) {
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );

  return this.http.patch( `${ environment.endpoint.creditRequest }/request/${ id }/cancel`, { }, { headers } )
  .pipe( retry(5), map( (response: any) => {
        return response.businessResponse;

      }), catchError((err) => {
        switch ( err.status ) {
          case 401:
            this.securityService.singOut();
            break;
          default:
          break;
        }
        return throwError(err.status);
  }));
}

viwedbyfira( id: any ) {
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );
  return this.http.patch( `${ environment.endpoint.creditRequest }/request/${ id }/viwedbyfira`, { }, { headers } )
  .pipe( retry(5), map( (response: any) => {
        return response.businessResponse;
      }), catchError((err) => {
        switch ( err.status ) {
          case 401:
            this.securityService.singOut();
            break;
          default:
          break;
        }
        return throwError(err.status);
  }));
}

viwedbyAdmin( id: any ) {
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );

  return this.http.patch( `${ environment.endpoint.creditRequest }/request/${ id }/viwedbyadmin`, { }, { headers } )
  .pipe( retry(5), map( (response: any) => {

        return response.businessResponse;

      }), catchError((err) => {
        switch ( err.status ) {
          case 401:
            this.securityService.singOut();
            break;
          default:
          break;
        }
        return throwError(err.status);
  }));
}
// -----------------------------------------------------------------------------
//  Update user
// -----------------------------------------------------------------------------
  update(  ) {
  }
// -----------------------------------------------------------------------------
//  Update business
// -----------------------------------------------------------------------------
updateField( user: any ) {
}
// -----------------------------------------------------------------------------
//  Get branch list
// -----------------------------------------------------------------------------
  getByID( id: string ) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );
    return this.http.get( `${ environment.endpoint.creditRequest }/request/${ id }` , { headers } )
      .pipe( retry(5), map( (response: any) => {
            return response.businessResponse;

          }), catchError((err) => {
            switch ( err.status ) {
              case 401:
                this.securityService.singOut();
                break;
              default:
              break;
            }
            return throwError(err.status);
      }));
  }
}