export class User {

    constructor(
        public name: string,
        public firstLastName: string,
        public secondLastName: string,

        public user: string,

        public role?: string,

        public brithday?: string,
        public gene?: string,

        public image?: string,
        public id?: string,
        public organization?: string,
        public creationDate?: string,
        public updateDate?: string,
        public state?: string

    ) {}

}
