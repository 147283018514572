import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment.js';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService } from './security.service';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User;

// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient, private securityService: SecurityService ) {
    console.log('User service loaded');
  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
createCustomer( user: User, password: string  ) {

      return this.http.post( `${ environment.endpoint.security }/security/user/customer`, { user, password } )
      .pipe( map( (response: any) => {

        return response.headerResponse;

      }));

  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
confirm( token: string, password: string ) {
}

// -----------------------------------------------------------------------------
//  Update user
// -----------------------------------------------------------------------------
  update( user: User ) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );
    return this.http.patch( `${ environment.endpoint.security }/security/user/${ user.id }`, user, { headers } )
    .pipe( map( (response: any) => {
      return response.businessResponse;

    }));
  }
// -----------------------------------------------------------------------------
//  Update business
// -----------------------------------------------------------------------------
updateField( user: any ) {
}
// -----------------------------------------------------------------------------
//  Get users list
// -----------------------------------------------------------------------------
  get() {
  }
// -----------------------------------------------------------------------------
//  Get users by id
// -----------------------------------------------------------------------------
getById( id: string ) {
}
}