import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment.js';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService } from './security.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
// ----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient, private securityService: SecurityService ) {
    console.log('User service loaded');
  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
created( data: any  ) {
const mail =  {
        subject: data.subject,
        type: data.type,
        receiver: data.receiver,
        data: JSON.stringify(data.dataMessage)
      };

return this.http.post( `${ environment.endpoint.notification }/mail`, mail ).pipe( map( (response: any) => {
      return response.headerResponse;
    }), catchError((err) => {
      switch ( err.status ) {
        case 401:
          this.securityService.singOut();
          break;
        default:
        break;
      }
      return throwError(err.status);
}));
  }

}
