import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../services/platform/security.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  fixMenu = true;
  statusSocketService = false;
  businessName: string;
  businessList: any[] = [];
  menuAdministration: any[] = [];
  menuModules: any[] = [];
  menuPlatform: any[] = [];

  constructor( public securityService: SecurityService) { }

  ngOnInit() {
    this.menuModules = this.securityService.dataSession.menu.modules;
    this.menuAdministration = this.securityService.dataSession.menu.administration;
    this.menuPlatform =  this.securityService.dataSession.menu.platform;
  }

  closeSession() {
    this.securityService.singOut();
  }

}
