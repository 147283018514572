import { Component, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit } from '@angular/core';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.js';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService, LocalStorageService, CreditService } from '../../services/services.index';
// -----------------------------------------------------------------------------
//  Interfaces
// -----------------------------------------------------------------------------
export interface UserData {
  folio: string;
  name: string;
  firstLastName: string;
  secondLastName: string;
  amountCredit: string;
  status: string;
  basePoints: string;
  _id: string;
}

@Component({
  selector: 'app-fira',
  templateUrl: './fira.component.html',
  styleUrls: ['./fira.component.scss']
})
export class FiraComponent implements AfterViewInit {
// -----------------------------------------------------------------------------
//  Filter section
// -----------------------------------------------------------------------------
  filterDraft = false;
  filterReview = false;
  filterObservation = false;
  filterApproved = true;
  filterRejected = false;
  filterNotreviewed = false;
  lastWeekApproval = false;
  lastWeekRequested = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  wordsToFind: any[] = [];
  showFilter = true;
  showFields = false;
// -----------------------------------------------------------------------------
  columns = [
    { name: 'indicator', description: 'indicator', active: true},
    { name: 'approveDate', description: 'Fecha de aprobación', active: true},
    { name: 'folio', description: 'Folio', active: true},
    { name: 'branch', description: 'Rama', active: true},
    { name: 'amountCredit', description: 'Monto del crédito', active: true},
    { name: 'basePoints', description: 'Puntos base', active: true},
    { name: 'estate', description: 'Entidad federativa', active: true}
  ];
  displayedColumns = [];
// -----------------------------------------------------------------------------
  showData = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  exampleDatabase: ExampleHttpDatabase | null;
  data: GithubIssue[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
// -----------------------------------------------------------------------------
//  Construct object 
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient,
    private router: Router,
    private creditService: CreditService,
    private localStorageService: LocalStorageService,
    private securityService: SecurityService,
    private snackBar: MatSnackBar ) {
  }
// -----------------------------------------------------------------------------
//  Init object
// -----------------------------------------------------------------------------
  ngAfterViewInit() {
    // -----------------------------------------------------------------------------
    const columnsSaved = this.localStorageService.get( 'requestListFiraComponent_columns' );
    if ( columnsSaved ) { this.columns = columnsSaved.columns; }
    this.columns.forEach( item => { if (item.active) { this.displayedColumns.push(item.name) }});
    // -----------------------------------------------------------------------------
    const paginationSaved = this.localStorageService.get( `requestListFiraComponent_pagination` );
    if ( paginationSaved ) {
      this.sort.active = paginationSaved.sort;
      this.sort.direction = paginationSaved.order;
      this.paginator.pageIndex = paginationSaved.page;
      this.paginator.pageSize = paginationSaved.pageSize;
      this.wordsToFind = paginationSaved.wordsToFind;
      this.filterDraft = paginationSaved.filterDraft;
      this.filterReview = paginationSaved.filterReview;
      this.filterObservation = paginationSaved.filterObservation;
      this.filterApproved = paginationSaved.filterApproved;
      this.filterRejected = paginationSaved.filterRejected;
      this.filterNotreviewed = paginationSaved.filterNotreviewed;
      this.lastWeekApproval = paginationSaved.lastWeekApproval;
      this.lastWeekRequested = paginationSaved.lastWeekRequested;
    }
    this.refresh();
  }
// -----------------------------------------------------------------------------
//  Get detail request
// -----------------------------------------------------------------------------
  getDetail( row: string ) {
    window.open(`${ environment.endpoint.creditRequest }/certificate/${ row }`, '_blank');

    this.creditService.viwedbyfira( row ).subscribe( response => {
        this.refresh();
    });

  }
// -----------------------------------------------------------------------------
//  Refresh data
// -----------------------------------------------------------------------------
  refresh() {
    this.exampleDatabase = new ExampleHttpDatabase(this.http, this.securityService);
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
// -----------------------------------------------------------------------------
    merge(this.sort.sortChange, this.paginator.page).pipe( startWith({}), switchMap(() => {
// -----------------------------------------------------------------------------
        let searchWords = '';
        this.wordsToFind.forEach(item => { searchWords = `${item.text}|${searchWords}`; });

        this.localStorageService.save( `requestListFiraComponent_pagination`, {
          sort: this.sort.active,
          order: this.sort.direction,
          page: this.paginator.pageIndex,
          pageSize: this.paginator.pageSize,
          wordsToFind: this.wordsToFind,
          filterDraft : this.filterDraft,
          filterReview : this.filterReview,
          filterObservation : this.filterObservation,
          filterApproved : this.filterApproved,
          filterRejected : this.filterRejected,
          filterNotreviewed : this.filterNotreviewed,
          lastWeekApproval : this.lastWeekApproval,
          lastWeekRequested : this.lastWeekRequested,
        } );
        const status = `${this.filterDraft ? '0' : ''}|${this.filterReview ? '1' : ''}|${this.filterObservation ? '2' : ''}|${this.filterApproved ? '3' : ''}|${this.filterRejected ? '4' : ''}`;
// -----------------------------------------------------------------------------
        this.isLoadingResults = true;
        return this.exampleDatabase.getRepoIssues(
          this.sort.active,
          this.sort.direction,
          this.paginator.pageIndex,
          this.paginator.pageSize,
          status, 
          searchWords,
          this.filterNotreviewed,
          this.lastWeekApproval,
          this.lastWeekRequested );
      }),
      map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.businessResponse.total;

          data.businessResponse.items.forEach( (item: any) => {
            switch (item.status) {
              case 0:
                  item.status = 'Borrador';
                  break;
              case 1:
                  item.status = 'En revisión';
                  break;
              case 2:
                  item.status = 'Con observaciones';
                  break;
              case 3:
                  item.status = 'Aprobado';
                  break;
               case 4:
                  item.status = 'Rechazada';
                  break;
              default:
                break;
            }
          });
          return data.businessResponse.items;
        }),
        catchError((err) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          switch ( err.status ) {
            case 401:
              this.snackBar.open('La sesión ha sido terminada', null, { duration: 5000 });
              this.securityService.singOut();
              break;
            default:
            this.snackBar.open('Servicio no diponible, intenta más tarde', null, { duration: 5000 });
            break;
          }

          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }
// -----------------------------------------------------------------------------
//
//
// PAGE FILTERS FUNCTIONS
//
//
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
//  Add word to search
// -----------------------------------------------------------------------------
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.wordsToFind.push({text: value.trim()});
    }
    if (input) {
      input.value = '';
    }
    this.search();
  }
// -----------------------------------------------------------------------------
//  Remove word to search
// -----------------------------------------------------------------------------
  remove(text: any): void {
    const index = this.wordsToFind.indexOf(text);
    if (index >= 0) {
      this.wordsToFind.splice(index, 1);
    }
    this.search();
  }
// -----------------------------------------------------------------------------x
//  Do search
// -----------------------------------------------------------------------------
  search(): void {
    this.refresh();
  }
// -----------------------------------------------------------------------------
//  Move columns
// -----------------------------------------------------------------------------
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.fieldSelect();
  }
// -----------------------------------------------------------------------------
//  Active field
// -----------------------------------------------------------------------------
  fieldSelect(): void {
    this.displayedColumns = [];
    this.columns.forEach( item => { if (item.active) { this.displayedColumns.push(item.name) }});
    this.localStorageService.save( 'requestListFiraComponent_columns', {
      columns: this.columns
    } );
  }
}
// -----------------------------------------------------------------------------
//
//
// GET DATA
//
//
// -----------------------------------------------------------------------------
  export interface GithubApi {
    businessResponse: {
      items: GithubIssue[];
      total: number;
    };
  }
// -----------------------------------------------------------------------------
  export interface GithubIssue {
    folio: string;
    name: string;
  }
// -----------------------------------------------------------------------------
export class ExampleHttpDatabase {
  constructor(private http: HttpClient, private securityService: SecurityService) {}

  getRepoIssues(sort: string, order: string, page: number, pageSize: number, status: string, search: string, notreviewedfira: boolean, lastWeekApproval: boolean, lastWeekRequested: boolean ): Observable<GithubApi> {
    const href = `${ environment.endpoint.creditRequest }/request`;
    let requestUrl = `${href}?sort=${ sort }&order=${ order }&page=${ page + 1}&perPage=${ pageSize }&`;
    requestUrl = `${ requestUrl }status=${ status }&`;
    requestUrl = `${ requestUrl }search=${ search }&`;
    
    if ( lastWeekApproval ) {
      requestUrl = `${ requestUrl }lastWeekApproval=active&`;
    }
    if ( lastWeekRequested ) {
      requestUrl = `${ requestUrl }lastWeekRequested=active&`;
    }
    if ( notreviewedfira ) {
      requestUrl = `${ requestUrl }notreviewedfira=active&`;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );
    return this.http.get<GithubApi>(requestUrl, { headers } );
  }
}