import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginGuard } from './guards/login.guard';

import {
  SecurityService,
  UserService
} from './services.index';


@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    SecurityService,
    LoginGuard,
     UserService,
  ],
  declarations: []
})
export class ServicesModule { }