import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {

  pageTitle = '';
  route: any[] = [];

  constructor( private router: Router,
               private brouserTitle: Title,
               private location: Location ) {

    this.getDataRoute().subscribe( data => {
      this.pageTitle = data.title;
      this.brouserTitle.setTitle( `${ this.pageTitle }`);
      this.route = data.route;
    });
  }

  ngOnInit() {
  }

  getDataRoute() {
    return this.router.events.pipe(
      filter( event =>  event instanceof ActivationEnd ),
      filter( (event: ActivationEnd) => event.snapshot.firstChild === null ),
      map( (event: ActivationEnd) => event.snapshot.data ));
  }

  goBack() {
    this.location.back();

  }



}
