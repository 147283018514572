import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PUBLIC_ROUTES } from './public.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material';
import {MatToolbarModule} from '@angular/material';
import {MatIconModule} from '@angular/material';
import {MatListModule} from '@angular/material';
import {MatExpansionModule} from '@angular/material';
import {MatButtonModule} from '@angular/material';
import {MatMenuModule} from '@angular/material';
import {MatCardModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material';
import {MatProgressSpinnerModule} from '@angular/material';
import {MatRadioModule} from '@angular/material';
import {MatStepperModule } from '@angular/material';
import {MatTooltipModule} from '@angular/material';
import {MatOptionModule, MatSelectModule} from '@angular/material';
import {MatButtonToggleModule} from '@angular/material';
import {MatDatepickerModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import {MatGridListModule} from '@angular/material';
import {MatTableModule, MatTableDataSource} from '@angular/material';
import {MatPaginatorModule} from '@angular/material';
import {MatSortModule} from '@angular/material/sort';
import {MatChipsModule} from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { PublicComponent } from './public.component';
import { CreditRequestComponent } from './credit-request/credit-request.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { ConceptInvestComponent } from './catalogs/concept-invest/concept-invest.component';
import { CatalogsModule } from './catalogs/catalogs.module';
import { ReportsComponent } from './reports/reports.component';
import { FiraComponent } from './fira/fira.component';


@NgModule({
  imports: [
    PUBLIC_ROUTES,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule, MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatChipsModule,
    MatCardModule,
    MatOptionModule, MatSelectModule, MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatDatepickerModule, MatInputModule, MatNativeDateModule,
    MatGridListModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],
  declarations: [
    PublicComponent,
    CreditRequestComponent,
    CatalogsComponent,
    ReportsComponent,
    FiraComponent,
  ]
})

export class PublicModule { }
