import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
// // -----------------------------------------------------------------------------
// //  Services
// // -----------------------------------------------------------------------------
import { SecurityService, UserService } from '../../services/services.index';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {

  version = '1.6.3';
  singin = true;
  hide = true;
  formSignIn: FormGroup;
  formSignUp: FormGroup;

// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private securityService: SecurityService,
               private router: Router,
               private snackBar: MatSnackBar ) {

  }
// -----------------------------------------------------------------------------
//  Object init
// -----------------------------------------------------------------------------
  ngOnInit() {
    const email = localStorage.getItem('email') || '';
    const rememberme = email.length > 0 ? true : false;

    this.formSignIn = new FormGroup({
      email           : new FormControl('', [Validators.required, Validators.email]),
      password        : new FormControl(null, Validators.required),
      rememberme      : new FormControl(rememberme)
    });

    this.formSignUp = new FormGroup({
      password        : new FormControl(null, Validators.required),
      password2       : new FormControl(null, [Validators.required]),
      email           : new FormControl('', [Validators.required, Validators.email])
    }, {
      validators: this.passwordConfirm('password', 'password2')
    });

  }
// -----------------------------------------------------------------------------
//  Validate writing correctly password
// -----------------------------------------------------------------------------
passwordConfirm( pass1: string, pass2: string ) {
  return ( group: FormGroup ) => {
    const value1 = group.controls[pass1].value;
    const value2 = group.controls[pass2].value;

    if ( value1 === value2) {
      return null;
    }

    return {
      passwordConfirm: true
    };
  };
}
// -----------------------------------------------------------------------------
//  Autenticate user
// -----------------------------------------------------------------------------
  signIn() {

    this.validateAllFormFields( this.formSignIn );
    if (!this.formSignIn.valid) {
      return;
    }

    this.securityService.signIn(
    this.formSignIn.value.email,
    this.formSignIn.value.password,
    this.formSignIn.value.rememberme ).subscribe( businessResponse => {

      console.log(businessResponse);

      if ( businessResponse ) {
        this.snackBar.open('Bienvenido a el panel de administración de ProSostenible', null, {
          duration: 5000, horizontalPosition: 'left'
        });

        if ( businessResponse.user.role === 'FIRA' ) {
          this.router.navigate(['/fira']);
        } else {
          this.router.navigate(['/requests']);
        }

      } else {
        this.snackBar.open('Acceso denegado', null, {
          duration: 5000, horizontalPosition: 'left'
        });
      }

    }, error => {

      switch ( error.status ) {
        case 406:
        this.snackBar.open('Usuario y/o contraseña incorrecta', null, {
          duration: 5000, horizontalPosition: 'left'
        });
        break;
        default:
        this.snackBar.open('Servicio no diponible, intenta más tarde', null, {
          duration: 5000, horizontalPosition: 'left'
        });
        break;
      }

    });
  }
// -----------------------------------------------------------------------------
//  Form validation
// -----------------------------------------------------------------------------
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  // -----------------------------------------------------------------------------
  //  Terms
  // -----------------------------------------------------------------------------
  goTerms() {
    window.open(`https://www.elbuensocio.com.mx/aviso-de-privacidad.html`, '_blank');
  }
}
