import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PublicModule } from './public/public.module';
import { ServicesModule } from './services/services.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './login/sign-in/sign-in.component';
import { TermsComponent } from './login/terms/terms.component';
import { MatSidenavModule} from '@angular/material';
import { MatToolbarModule} from '@angular/material';
import { MatIconModule} from '@angular/material';
import { MatListModule} from '@angular/material';
import { MatExpansionModule, MatInputModule} from '@angular/material';
import { MatButtonModule} from '@angular/material';
import { MatMenuModule} from '@angular/material';
import { MatCardModule} from '@angular/material';
import { MatCheckboxModule} from '@angular/material';
import { MatRadioModule} from '@angular/material';
import { MatStepperModule } from '@angular/material';
import { MatTooltipModule} from '@angular/material';
import { MatOptionModule, MatSelectModule} from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PublicModule,
    ServicesModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule, MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatOptionModule, MatSelectModule, MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }


