import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './login/sign-in/sign-in.component';
import { TermsComponent } from './login/terms/terms.component';

const routes: Routes = [
  {
    path: 'signin',
    component: SignInComponent,
    data: {
      title: 'Inicio de sesión',
      route: []
    }
  },
  {
    path: 'terms',
    component: TermsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const APP_ROUTES = RouterModule.forRoot( routes, { useHash: true } );