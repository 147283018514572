import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SecurityService } from '../platform/security.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor( private securityService: SecurityService,
               private router: Router ) {
    console.log('Login guard activated');
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if ( this.securityService.isAuthenticated() ) {
      if ( this.securityService.getRole() === 'FIRA' ) {
        if (  route.url.length === 0 || route.url[0].path !== 'fira') {
          this.router.navigate(['/fira']);
          return false;
        }
      }

      return true;

    } else {
      this.router.navigate(['/signin']);
      return false;
    }
  }
}